<template>
    <CModal
        color="warning"
        title="Eliminar Registro"
        :show.sync="deleteTaskModal"
    >
        <label>Esta seguro que desea Eliminar su registro?</label>
        <template #footer>
            <CButton @click="deleteTaskModal = false" color="primary">Cancelar</CButton>
            <CButton @click="deleteTask" color="warning">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>

export default {
    name: "ModalDelete",
    props: ['deleteTaskModal'],
    methods: {
        deleteTask() {
            this.$emit('deleteTask');
        }
    }
}
</script>
