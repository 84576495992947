<template>
    <CModal
        color="warning"
        title="Crear incidencia"
        :show.sync="incidenceModal"
    >
        <CRow>
            <CCol col="12" lg="12" v-if="isEmployee">
                <CSelect 
                    :plain="true"
                    :options="employees"
                    :label="$t('employees.title')" 
                    :isValid="incidence.employee_valid"
                    :value.sync="incidence.employee_id"
                    :placeholder="$t('employees.placeholder')" 
                    :invalidFeedback="$t('employees.invalid')"
                ></CSelect>
            </CCol>
            <CCol col="12" lg="12">
                <CSelect 
                    :plain="true"
                    :options="incidences"
                    :label="$t('incidences.title')" 
                    :isValid="incidence.incidence_valid"
                    :value.sync="incidence.incidence_id"
                    :invalidFeedback="$t('employees.invalid')"
                    :placeholder="$t('incidences.placeholder')" 
                ></CSelect>
            </CCol>
            <CCol col="6" lg="6">
                <label>Si su incidencia es de descuento marque la casilla de lo contrario sera un bono.</label>
                <CSwitch
                    color="success"
                    :checked.sync="incidence.discount"
                />
            </CCol>
            <CCol col="6" lg="6">
                <label>Si su incidencia es por porcentage marque la casilla de lo contrario sera un monto fijo.</label>
                <CSwitch
                    color="success"
                    :checked.sync="incidence.percentage"
                />
            </CCol>
            <CCol col="12" lg="12">
                <CInput 
                    type="text" 
                    v-model="incidence.amount" 
                    :isValid="incidence.amount_valid"
                    :label="$t('fields.amount.label')" 
                    :placeholder="$t('fields.amount.placeholder')" 
                    :invalidFeedback="$t('fields.amount.invalid')"
                ></CInput>
            </CCol>
        </CRow>
        <template #footer>
            <CButton @click="incidenceModal = false" color="primary">Cancelar</CButton>
            <CButton @click="addTask" color="primary">Agregar</CButton>
        </template>
    </CModal>
</template>

<script>

export default {
    name: "ModalIncidence",
    props: ['incidenceModal', "incidence", "employees", "incidences", "isEmployee"],
    methods: {
        addTask() {
            this.$emit('addTask');
        }
    }
}
</script>
