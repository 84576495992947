<template>
    <CModal
        color="primary"
        title="Agregar Localidad"
        :show.sync="locationModal"
    >
        <CRow>
            <CCol col="12" lg="12" v-if="isEmployee">
                <CSelect 
                    :plain="true"
                    :options="employees"
                    :label="$t('employees.title')" 
                    :value.sync="location.employee_id"
                    :isValid="location.employee_valid"
                    :placeholder="$t('employees.placeholder')" 
                    :invalidFeedback="$t('employees.invalid')"
                ></CSelect>
            </CCol>
            <CCol col="12" lg="12">
                <CSelect 
                    :plain="true"
                    :options="locations"
                    :label="$t('locations.title')" 
                    :value.sync="location.location_id"
                    :isValid="location.location_valid"
                    :placeholder="$t('locations.placeholder')" 
                    :invalidFeedback="$t('employees.invalid')"
                ></CSelect>
            </CCol>
            <CCol col="12" lg="12">
                <label>Si su bono es por porcentage marque la casilla de lo contrario sera un monto fijo.</label>
                <CSwitch
                    color="success"
                    :checked.sync="location.percentage"
                />
            </CCol>
            <CCol col="12" lg="12">
                <CInput 
                    type="text" 
                    v-model="location.amount" 
                    :isValid="location.amount_valid"
                    :label="$t('fields.amount.label')" 
                    :placeholder="$t('fields.amount.placeholder')" 
                    :invalidFeedback="$t('employees.invalid')"
                ></CInput>
            </CCol>
            <CCol col="2" lg="2">
                <label>Lunes</label>
                <CSwitch
                    color="success"
                    :checked.sync="location.day[0].value"
                />
            </CCol>
            <CCol col="2" lg="2">
                <label>Martes</label>
                <CSwitch
                    color="success"
                    :checked.sync="location.day[1].value"
                />
            </CCol>
            <CCol col="2" lg="2">
                <label>Miercoles</label>
                <CSwitch
                    color="success"
                    :checked.sync="location.day[2].value"
                />
            </CCol>
            <CCol col="2" lg="2">
                <label>Jueves</label>
                <CSwitch
                    color="success"
                    :checked.sync="location.day[3].value"
                />
            </CCol>
            <CCol col="2" lg="2">
                <label>Viernes</label>
                <CSwitch
                    color="success"
                    :checked.sync="location.day[4].value"
                />
            </CCol>
            <CCol col="2" lg="2">
                <label>Sabado</label>
                <CSwitch
                    color="success"
                    :checked.sync="location.day[5].value"
                />
            </CCol>
            <CCol col="2" lg="2">
                <label>Domingo</label>
                <CSwitch
                    color="success"
                    :checked.sync="location.day[6].value"
                />
            </CCol>
            <CCol col="12" lg="12" v-if="location.day_valid">
                <label style="width: 100%; margin-top: 0.25rem; font-size: 80%; color: #e55353;">Por favor seleccione los días en su localidad</label>
            </CCol>
        </CRow>
        <template #footer>
            <CButton @click="locationModal = false" color="primary">Cancelar</CButton>
            <CButton @click="addLocation" color="primary">Agregar</CButton>
        </template>
    </CModal>
</template>

<script>

export default {
    name: "ModalLocation",
    props: ['locationModal', "locations", "employees", "location", "isEmployee"],
    methods: {
        addLocation() {
            this.$emit('addLocation');
        }
    }
}
</script>
