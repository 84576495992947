<template>
    <div v-if="isLoading" style="position: absolute; margin-left: -30px; margin-top: -20px; height: 100%; width: 100%; z-index:999999; background-color: rgba(0, 0, 0, 0.5);" class="d-flex justify-content-between align-items-center">
        <CSpinner
            color="primary"
            style="width:4rem; height:4rem; margin: 0 auto !important; position: relative !important;"
        />
    </div>
</template>

<script>

export default {
    name: "Loading",
    props: ['isLoading']
}
</script>
